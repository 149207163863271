﻿export default (e, value, onChange) => {
    const randomNum = Math.floor(Math.random() * 10000);
    const inputName = e.label + randomNum;
    return <div key={'div' + e.label} className="form-check">
        <input id={inputName} name={inputName} checked={value.includes(e.value)} onChange={event => { event.target.checked ? value.push(e.value) : value = value.filter(i => i != e.value); onChange(value) }} className="form-check-input" type="checkbox"></input>
        <label htmlFor={inputName} className="form-check-label">
            {e.label}
        </label>
    </div>
}
