﻿export default (element) =>
    <div key={element.content.key} className="col-12 text-start" style={{ marginBottom: "20px" }}>
        <h3 className="h3-custom">{element.content.props.schema['title']}</h3>
        <div className="d-inline">
            <input readOnly={true} id={'inputUpload' + element.content.props.name} className="col-9 mt-2 text-start" style={{ color: 'rgb(0, 133, 255)', border: '2px solid rgb(238, 238, 238)', padding: 10 + 'px', borderRadius: 10 + 'px', cursor: 'default' }} />
            <button onClick={event => ConfigureTemplateHelper.FileUpload(element.content.props.name)} type="button" className="btn btn-primary btn-sm" style={{ fontSize: 14 + 'px', marginLeft: '3%' }}>
                <i className="fa-solid fa-arrow-up-from-bracket me-2"></i>
                Upload
            </button>
        </div>
    </div>
