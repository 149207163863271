﻿function toggleDropMenu(menuId, btn) {
    if (document.getElementById(menuId).className.includes('show')) {
        document.getElementById(menuId).className = document.getElementById(menuId).className.replace('show', '').trim()
        btn.className = btn.className.replace('fa-solid fa-square-minus', 'fa-solid fa-square-plus').trim()
    } else {
        document.getElementById(menuId).className = document.getElementById(menuId).className + " show";
        btn.className = btn.className.replace('fa-solid fa-square-plus', 'fa-solid fa-square-minus').trim()
    }
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

function allTenantsObject(props, checked) {
    let tempObj = {};
    let inputs = document.getElementById('tenantsMenu').getElementsByClassName('form-check-input');
    if (checked) {
        props.properties.forEach((v) => {
            tempObj[v.content.key] = v.content.props.schema.items.enum;
        });
    } else {
        props.properties.forEach((v) => {
            tempObj[v.content.key] = [];
        });
    }
    let nData = JSON.parse(getFormData()) || formRef.current.props.formData;
    nData["tenants"] = tempObj;
    formRef.current.onChange(nData, '', 'root');
    for (var input of inputs) {
        input.checked = checked;
    }
}

function allTenantsGroup(e, p, groupId) {
    let chk = e.target || e;
    if (e.target == undefined) {
        chk.checked = !chk.checked;
    }
    chk.checked ?
        p.content.props.onChange(p.content.props.schema.items.enum, '', p.content.props.idSchema.$id) :
        p.content.props.onChange([], '', p.content.props.idSchema.$id);
    let inputs = document.getElementById(groupId).getElementsByClassName('form-check-input');
    for (var input of inputs) {
        input.checked = chk.checked;
    }
}

export default (props) => {
    return <div className="dropdown">
        <div className="form-check">
            <input onChange={e => { allTenantsObject(props, e.target.checked); }} className="form-check-input" type="checkbox" id={props.idSchema.$id}></input>
            <label className="form-check-label" htmlFor={props.idSchema.$id}>
                {props.title}
                <i style={{ cursor: "pointer", marginLeft: ".4rem" }} onClick={e => toggleDropMenu('tenantsMenu', e.target)} className="fa-solid fa-square-minus"></i>
            </label>
        </div>
        <div id="tenantsMenu" className="dropdown-menu ms-3 border-0 show">
            {props.properties.map(p => {
                if (p.content.props.schema.items.enum.length > 0) {
                    let parentKey = p.content.key.replace(' ', '').replace('.', '').replace(',', '');
                    let chkId = 'chk_' + parentKey;
                    return <div key={parentKey} className="dropdown mb-2">
                        <div className="form-check">
                            <input onChange={e => { allTenantsGroup(e, p, (p.content.key + 'Menu').replace(' ', '')); }} className="form-check-input parent-tenant-group" style={{ marginRight: ".4rem", float: "none!important" }} type="checkbox" id={chkId}></input>
                            <label onClick={e => { allTenantsGroup(document.getElementById(chkId), p, (p.content.key + 'Menu').replace(' ', '')); e.stopPropagation(); }} className="form-check-label" htmlFor={p.content.name}>
                                {p.content.key}
                            </label>
                            <i style={{ cursor: "pointer", marginLeft: ".4rem" }} onClick={e => toggleDropMenu((p.content.key + 'Menu').replace(' ', ''), e.target)} className="fa-solid fa-square-plus"></i>
                        </div>
                        <div id={(p.content.key + 'Menu').replace(' ', '')} className="collapse mt-2 mb-4 ms-4 card card-body">
                            {
                                p.content
                            }
                        </div>
                    </div>
                } else {
                    return <div key={parentKey} className="form-check">
                        <input onChange={props.onAddClick} className="form-check-input" type="checkbox" id={p.content.name}></input>
                        <label className="form-check-label" htmlFor={p.content.name}>
                            {p.content.key}
                        </label>
                    </div>
                }
            })}
        </div>
    </div>
}
