﻿import ReactDOM from "react-dom/client";
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/bootstrap-4';
import React, { createRef } from 'react';
import CustomCheckBox from "./customRenderers/CustomCheckBox";
import CustomArrayCheckBoxMenu from "./customRenderers/CustomArrayCheckBoxMenu";
import CustomArgusFile from "./customRenderers/CustomArgusFile";
import CustomExcelFile from "./customRenderers/CustomExcelFile";
import CustomParentCheckBox from "./customRenderers/CustomParentCheckBox";

let jsonFormData = '{}';
let jsonUiSchema;
let jsonSchema = {};
let root;
window.formRef = createRef();

const onChange = ({ formData }, e) => {
    ConfigureTemplateHelper.FormDataChanged(formData);
}



const CustomCheckWidget = (props) => {
    const { onChange } = props;
    let { value } = props;
    return (
        props.options.enumOptions.map((e, i) => {
            return CustomCheckBox(e, value, onChange);
        })
    );
};

const CustomParentCheckWidget = (props) => {
    const { onChange } = props;
    let { value } = props;
    console.log(props);
    return (
        props.options.enumOptions.map((e, i) => {
            return CustomParentCheckBox(e, value, onChange);
        })
    );
};

const widgets = {
    customCheck: CustomCheckWidget
};

function sortProps(a, b) {
    return a.content.props.schema['x-sort-order'] - b.content.props.schema['x-sort-order'];
}

function ObjectFieldTemplate(props) {
    let sortedList = props.properties.sort(sortProps);
    if (props.schema.hasOwnProperty('x-checkbox-widget')) {
        if (props.schema['x-checkbox-widget']) {
            return (
                CustomArrayCheckBoxMenu(props)
            );
        }
    }
    return (
        
        <div>
            {props.title}
            {props.description}
            {sortedList.map(element => {
                if (element.content.props.schema.hasOwnProperty('x-is-argus-file')) {
                    if (element.content.props.schema['x-is-argus-file']) {
                        return CustomArgusFile(element)
                    }
                }
                if (element.content.props.schema.hasOwnProperty('x-is-excel-file')) {
                    if (element.content.props.schema['x-is-excel-file']) {
                        return CustomExcelFile(element)
                    }
                }
                return <div key={element.content.key} className="property-wrapper">{element.content}</div>
            })}
        </div>
    );
}

window.document.onload = (eventArgs) => {
    jsonFormData = null;
}

window.resetFormData = (currentData) => {
    jsonFormData = currentData;
}

window.updateFormData = (currentData) => {
    jsonFormData = JSON.parse(currentData);
}

window.getFormData = () => {
    return JSON.stringify(jsonFormData);
}

window.setJsonFormProp = (data, uiSchema) => {
    jsonFormData = data;
}

window.setFormData = (uiSchema) => {
    jsonUiSchema = uiSchema;
}

window.setFormDataPropertyValue = (property, value) => {
    if (jsonFormData == '') {
        jsonFormData = '{}';
    }
    if (jsonFormData == null || jsonFormData == undefined) {
        jsonFormData = '{}';
    }
    jsonFormData = JSON.parse('{}');
    jsonFormData[property] = value;
}

function renderForm() {
    return <Form id="reactForm" schema={jsonSchema} formData={jsonFormData} uiSchema={jsonUiSchema} validator={validator}
        onChange={onChange} widgets={widgets} templates={{ ObjectFieldTemplate }} ref={formRef}>
        <div>
            <button id="btnReactForm" hidden="hidden" type="submit">Submit</button>
        </div>
    </Form>;
}

window.refreshForm = (schema, data, uiSchema) => {
    jsonSchema = JSON.parse(schema || '{}');
    jsonFormData = JSON.parse(data || '{}');
    jsonUiSchema = JSON.parse(uiSchema || '{}');
    jsonSchema = JSON.parse(schema || '{}');
    root.render(renderForm());
}

window.renderApp = (schema) => {
    try {
        const domElement =
            document.getElementById("react-app") ||
            document.querySelector("#react-app");
        if (domElement) {
            root = ReactDOM.createRoot(domElement);
            jsonSchema = JSON.parse(schema || '{}');
            root.render(renderForm());
        }
    } catch (e) {
        console.log(e);
    }
};